.react-tel-input {
  .form-control {
    width: 100%;
    height: calc(2.4em + 0.75rem + 2px);
    border-color: var(--light-gray);
    font-size: initial;

    & + .flag-dropdown {
      background-color: #e9ecef;
      border-color: var(--light-gray);
      border-radius: 5px 0 0 5px;

      .selected-flag {
        border-radius: 5px 0 0 5px;
      }

      .country-list {
        margin-top: 2px;
      }
    }

    &:focus {
      border-color: var(--primary) !important;
    }
  }
}
