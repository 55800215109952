@import './customBootstrap';
@mixin card($cardName, $childWidth, $childHeight) {
  .#{$cardName} {
    align-items: center;
    height: $childHeight;
    margin-right: 1.3rem;
    margin-bottom: 1.3rem;
    position: relative;
    transition: box-shadow .2s ease-in-out;
    width: 100%;
    max-width: $childWidth;
    background: #fff;
    border-radius: 14px;
    border:1px solid var(--light-gray);

    &:hover {
      box-shadow: 0 4px 12px rgba(50, 50, 93, 0.08);
      transition: box-shadow .3s ease-in-out;
    }


    &.checked {
      border-color: var(--primary);

      &:after {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: var(--primary);
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--><path fill="%23ffffff" d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"/></svg>');
        background-repeat: no-repeat;
        content: '';
        background-size: 50%;
        background-position: center;
      }
    }
  }
}

@mixin icon-wrapper-circle($iconWrapperName, $size: 56px, $background: #1c2870, $color: #fff, $fontSize: 32px) {
  .#{$iconWrapperName} {
    width: $size;
    height: $size;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    background: $background;
    color: $color;
    font-size: $fontSize;
  }
}

@mixin placeholder-color($color: var(--black)) {
  //PLACEHOLDER
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color !important;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color !important;
  }
}

@mixin square {
  content: "";
  display: block;
  background: #757a97;
  width: .7rem;
  height: .7rem;
  position: absolute;
  top: .9rem;
  margin-left: -1.5rem;
}
